.dnaMatrixWindow {
  position: fixed; /* Makes it stick to the viewport */
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%; /* Full width */
  block-size: 100vh; /* Full viewport height */
  z-index: -1; /* Behind all other content */
  overflow: hidden;
  background-color: black; /* Set background to black */
  color: black;
  pointer-events: none; /* Ensure that other elements can be interacted with */
}

.dnaMatrixBackground {
  inline-size: 100%;
  block-size: 100vh;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  z-index: -1;
  background-color: black; /* Set background to black */
}

.dnaMatrixCanvas {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
}
