/* SponsorDashboard.css */

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #f3f4f6;
    color: rgb(0, 0, 0);
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3 {
    margin: 0;
    padding: 0;
    color: #1a202c;
  }
  
  h1 {
    font-size: 2.25rem;
  }
  
  h2 {
    font-size: 1.875rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .bg-white {
    background-color: #fff;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .p-6 {
    padding: 1.5rem;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .rounded-xl {
    border-radius: 1rem;
  }
  
  .shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  }
  
  .shadow-lg {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .mb-8 {
    margin-bottom: 2rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-4xl {
    font-size: 3.65rem;
  }
  
  .text-indigo-900 {
    color: #1a202c;
  }
  
  .text-indigo-800 {
    color: #2c5282;
  }
  
  .text-gray-50 {
    color: #f9fafb;
  }
  
  .text-gray-100 {
    color: #f7fafc;
  }
  
  .text-gray-600 {
    color: #000000;
  }
  
  .text-gray-800 {
    color: #000000;
  }
  
  .text-white {
    color: #fff;
  }
  
  .bg-gray-50 {
    background-color: #f9fafb;
  }
  
  .bg-gray-100 {
    background-color: #f7fafc;
  }
  
  .bg-gray-200 {
    background-color: #edf2f7;
  }
  
  .bg-green-100 {
    background-color: #f0fff4;
  }
  
  .text-green-800 {
    color: #22543d;
  }
  
  .bg-yellow-100 {
    background-color: #fffff0;
  }
  
  .text-yellow-800 {
    color: #744210;
  }
  
  .bg-red-100 {
    background-color: #fff5f5;
  }
  
  .text-red-800 {
    color: #9b2c2c;
  }
  
  .border {
    border-width: 1px;
  }
  
  .border-gray-300 {
    border-color: #e2e8f0;
  }
  
  .border-collapse {
    border-collapse: collapse;
  }
  
  .table-auto {
    width: auto;
  }
  
  .table-fixed {
    width: 100%;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .leading-normal {
    line-height: 1.5;
  }
  
  .leading-snug {
    line-height: 1.375;
  }
  
  .leading-tight {
    line-height: 1.25;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .font-normal {
    font-weight: 400;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .mr-4 {
    margin-right: 1rem;
  }
  
  .ml-4 {
    margin-left: 1rem;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .mt-8 {
    margin-top: 2rem;
  }
  
  .grid {
    display: grid;
  }
  
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  
  .gap-4 {
    grid-gap: 1rem;
  }
  
  .gap-8 {
    grid-gap: 2rem;
  }
  
  .w-full {
    width: 100%;
  }
  
  .h-full {
    height: 100%;
  }
  
  .max-w-7xl {
    max-width: 80rem;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .overflow-x-auto {
    overflow-x: auto;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
  }
  
  .top-3 {
    top: 0.75rem;
  }
  
  .left-3 {
    left: 0.75rem;
  }
  
  .border {
    border-width: 1px;
  }
  
  .border-gray-300 {
    border-color: #e2e8f0;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .focus\:outline-none:focus {
    outline: 0;
  }
  
  .focus\:ring-2:focus {
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }
  
  .focus\:ring-blue-500:focus {
    border-color: #3182ce;
  }
  
  .focus\:ring-offset-2:focus {
    box-shadow: 0 0 0 4px rgba(66, 153, 225, 0.3);
  }
  
  .bg-blue-500 {
    background-color: #3182ce;
  }
  
  .hover\:bg-blue-600:hover {
    background-color: #2b6cb0;
  }
  
  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  
  .pl-10 {
    padding-left: 2.5rem;
  }
  
  .border-gray-300 {
    border-color: #e2e8f0;
  }
  
  .ring-blue-500 {
    border-color: #3182ce;
  }
  
  .table-auto {
    width: auto;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .leading-normal {
    line-height: 1.5;
  }
  
  .leading-snug {
    line-height: 1.375;
  }
  
  .leading-tight {
    line-height: 1.25;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .font-normal {
    font-weight: 400;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .mr-4 {
    margin-right: 1rem;
  }
  
  .ml-4 {
    margin-left: 1rem;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .mt-8 {
    margin-top: 2rem;
  }
  
  .grid {
    display: grid;
  }
  
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  
  .gap-4 {
    grid-gap: 1rem;
  }
  
  .gap-8 {
    grid-gap: 2rem;
  }
  
  .w-full {
    width: 100%;
  }
  
  .h-full {
    height: 100%;
  }
  
  .max-w-7xl {
    max-width: 80rem;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .overflow-x-auto {
    overflow-x: auto;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
  }
  
  .top-3 {
    top: 0.75rem;
  }
  
  .left-3 {
    left: 0.75rem;
  }

/* SponsorDashboard.module.css */

.sponsorDashboard body {
  color: #000000 !important; /* Black for body text */
}

.sponsorDashboard p, 
.sponsorDashboard span, 
.sponsorDashboard div {
  color: #333333 !important; /* Dark gray for general text elements */
}

.sponsorDashboard h1 {
  color: #1a1a1a !important; /* Very dark gray, almost black for main headers */
}

.sponsorDashboard h2, 
.sponsorDashboard h3 {
  color: #2c2c2c !important; /* Dark gray for subheaders */
}

.sponsorDashboard h4, 
.sponsorDashboard h5, 
.sponsorDashboard h6 {
  color: #4d4d4d !important; /* Medium gray for smaller headers */
}

.sponsorDashboard .legend-text {
  color: #0000FF !important; /* Blue for legend text, to make it stand out */
}

.sponsorDashboard .tooltip-text {
  color: #000000 !important; /* Black for tooltip text, ensuring readability */
}
