/* MobileHomepage.css */
:root {
  --polycypher-purple: #470089;
  --polycypher-dark-blue: #1e3a8a;
  --polycypher-pink: #be2471;
  --polycypher-light-blue: #4b9ed1;
  --polycypher-white: #ffffff;
  --polycypher-black: #000000;
}

.polycypher-mobile-container {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  background-color: var(--polycypher-white);
  color: var(--polycypher-black);
}

.polycypher-mobile-header {
  background-color: var(--polycypher-white);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.polycypher-mobile-logo {
  height: 40px;
  width: auto;
}

.polycypher-mobile-nav-link {
  color: var(--polycypher-purple);
  text-decoration: none;
  margin-left: 1rem;
  font-weight: 500;
}

.polycypher-mobile-main {
  padding: 2rem 1rem;
}

.polycypher-mobile-hero {
  text-align: center;
  margin-bottom: 3rem;
}

.polycypher-mobile-hero-title {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  color: var(--polycypher-white) !important;
  background-color: var(--polycypher-purple) !important;
  padding: 1rem 1.5rem !important;
  margin-bottom: 1.5rem !important;
  text-align: center !important;
  line-height: 1.2 !important;
  letter-spacing: 0.05em !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
}

.polycypher-mobile-hero-text {
  color: var(--polycypher-dark-blue);
  margin-bottom: 1.5rem;
}

.polycypher-mobile-cta-button {
  display: inline-block;
  background-color: var(--polycypher-purple);
  color: var(--polycypher-white);
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.polycypher-mobile-cta-button:hover {
  background-color: var(--polycypher-dark-blue);
}

.polycypher-mobile-info, .polycypher-mobile-team, .polycypher-mobile-mailing-list {
  background-color: var(--polycypher-white);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.polycypher-mobile-info-title, .polycypher-mobile-team-title, .polycypher-mobile-mailing-list-title {
  color: var(--polycypher-purple);
  margin-bottom: 1rem;
}

.polycypher-mobile-info-text, .polycypher-mobile-mailing-list-text {
  color: var(--polycypher-dark-blue);
  margin-bottom: 1rem;
}

.polycypher-mobile-info-logo {
  width: 50px;
  height: auto;
  display: block;
  margin: 1rem auto;
}


.polycypher-mobile-team-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 0.5rem;
}

.polycypher-mobile-team-member {
  flex: 0 1 calc(33.333% - 2rem);
  min-width: 25rem;
  max-width: 80rem;
  text-align: center;
  background-color: var(--polycypher-light-blue);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -ms-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -o-transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.polycypher-mobile-team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.polycypher-mobile-team-image {
  width: 120px;
  height: 120px;
  border-radius: 40%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: 3px solid var(--polycypher-purple);
  -webkit-border-radius: 40%;
  -moz-border-radius: 40%;
  -ms-border-radius: 40%;
  -o-border-radius: 40%;
}

.polycypher-mobile-team-name {
  color: var(--polycypher-dark-blue);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.polycypher-mobile-team-role {
  color: var(--polycypher-purple);
  font-size: 1rem;
  font-weight: bold;
}



.polycypher-mobile-subscribe-form {
  display: flex;
  flex-direction: column;
}

.polycypher-mobile-subscribe-input {
  padding: 0.8rem;
  border: 1px solid var(--polycypher-light-blue);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.polycypher-mobile-subscribe-button {
  background-color: var(--polycypher-purple);
  color: var(--polycypher-white);
  border: none;
  padding: 0.8rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.polycypher-mobile-subscribe-button:hover {
  background-color: var(--polycypher-dark-blue);
}

.polycypher-mobile-footer {
  background-color: var(--polycypher-dark-blue);
  color: var(--polycypher-white);
  text-align: center;
  padding: 2rem 1rem;
}

.polycypher-mobile-footer-text {
  margin-bottom: 0.5rem;
}

.polycypher-mobile-social-icons {
  margin-top: 1rem;
}

.polycypher-mobile-social-link {
  margin: 0 0.5rem;
}

.polycypher-mobile-social-logo {
  height: 24px;
  width: 24px;
}