/* General Styles for Card Container and Insight Cards */
.card-container, .insight-card {
    position: relative; /* Position relative is required to set z-index */
    z-index: 10;
    margin: 0 auto;
  }
  
  /* Section Styles */
  .section-with-background {
    margin-block-end: 40px;
    text-align: center;
    background: none;
    background-color: transparent;
    position: relative; /* To stack on top of the animation */
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center; /* Centers the entire grid within the card container */
    align-items: start;
    gap: 20px;
    padding: 20px;
  }
  
  /* Target a single card scenario */
  @media (max-width: 768px) {
    .card-container {
      grid-template-columns: repeat(1, minmax(250px, 1fr)); /* This forces only one column layout */
    }
  }
  
  /* Insight Card Styles */
  .insight-card {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    max-width: 300px; /* Use max-width instead of max-inline-size for consistency */
    margin: 0 auto; /* Center the cards horizontally */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 20px;
    position: relative;
    overflow: hidden; /* Clip the pseudo-element to card's border */
  }
  
  /* Pseudo-element for gradient background */
  .insight-card::before {
    content: '';
    position: absolute;
    top: -100%; left: -100%; /* Slightly larger than the card */
    right: -100%; bottom: -100%;
    background: linear-gradient(135deg, #c432d1, #6808ac, #a83279);
    border-radius: 12px; /* Slightly larger to maintain border radius during rotation */
    transition: transform 0.5s ease-in-out;
    z-index: -1;
  }
  
  /* Rotate pseudo-element on hover */
  .insight-card:hover::before {
    transform: rotate(180deg);
    background: linear-gradient(135deg, #a132d1, #1808ac, #328ea8);
  }
  
  /* Positioning content above the pseudo-element */
  .insight-card h3, .insight-card p {
    color: #fff !important; /* Ensure text color is always white, even on hover */
    position: relative;
    z-index: 1;
  }
  
  .insight-card h3 {
    font-size: 1.5rem;
    margin-block-end: 10px;
  }
  
  .insight-card p {
    font-size: 1rem;
    margin: 0;
    padding: 0 15px 15px;
  }  




