/* Contact.css */

body, html {
    background-color: #100022 !important;
    color: #FFFFFF !important;
    font-family: Helvetica, Roboto, sans-serif !important;
  }
  
  .container {
    background-color: #100022 !important;
    padding: 2rem !important;
  }
  
  .text-center h1 {
    color: #FFFFFF !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
    font-size: 2.5rem !important;
  }
  
  .contact-form {
    background-color: #000000 !important;
    border-radius: 15px !important;
    padding: 2rem !important;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1) !important;
  }
  
  .contact-form h3 {
    color: #FFFFFF !important;
    margin-bottom: 1.5rem !important;
    text-align: center !important;
  }
  
  .contact-form label {
    color: #FFFFFF !important;
    display: block !important;
    margin-bottom: 0.5rem !important;
  }
  
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100% !important;
    padding: 0.5rem !important;
    border-radius: 5px !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #FFFFFF !important;
  }
  
  .contact-form textarea {
    min-height: 100px !important;
  }
  
  .contact-form button {
    background-color: #007bff !important;
    color: #FFFFFF !important;
    padding: 0.5rem 1rem !important;
    border-radius: 5px !important;
    border: none !important;
    cursor: pointer !important;
    transition: background-color 0.3s !important;
  }
  
  .contact-form button:hover {
    background-color: #0056b3 !important;
  }
  
  .response-message {
    margin-top: 1rem !important;
    color: #FFFFFF !important;
    text-align: center !important;
  }