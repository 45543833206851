/* Existing CSS */
body {
  background-color: black;
  color: rgb(0, 0, 0);
  font-family: "Helvetica", "Roboto", sans-serif;
}

.card-header h3 {
  text-align: center;
  color: white;
}

.card {
  background: transparent;
  border: none;
}

.card-body {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #888;
  border-radius: 15px;
}

.form-group label {
  color: white;
}

.form-control {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-block-end: 2px solid white;
  border-radius: 0;
  color: white;
}

.form-control:focus {
  box-shadow: none;
  border-color: white;
}

.btn-primary {
  background-color: white;
  color: black;
  border: 2px solid #cccccc; /* Adding a light border for definition */
  padding: 10px 15px; /* Increased padding for better visual and clickable area */
  font-weight: bold;
  font-size: 16px; /* Setting a standard font size */
  margin-block-start: 5px; /* Adjust margin for better spacing */
  border-radius: 5px; /* Keeping the border-radius for a slightly rounded look */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition for hover effects */
  text-transform: uppercase; /* Optional: Makes text uppercase for a more button-like appearance */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
  box-shadow: 0 2px 4px rgba(12, 10, 147, 0.1); /* Subtle shadow for depth */
}

.btn-primary:hover, .btn-primary:focus {
  background-color: rgb(19, 30, 125); /* Darker background on hover */
  color: white; /* White text on hover */
  border-color: #56045e; /* Slightly darker border on hover */
  box-shadow: 0 3px 6px rgba(35, 15, 212, 0.2); /* Slightly larger shadow on hover for a 'lifting' effect */
}

.card, h1 {
  background: rgba(0, 0, 0, 0.269);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  padding: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}





.card1 {
  background: rgba(0, 0, 0, 0.269);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  padding: 15px;
}





/* Ensuring readability of text */
.card, h1, h3, label {
  color: white;
}

/* Center align the title */
h1 {
  text-align: center;
}

/* Margin and padding adjustments */
.card {
  margin: 15px;
  padding: 15px;
}

.card1 {
  margin-top: 15px;
  margin-bottom: 50px;
  padding: 15px;
}



.logoWrapper {
  position: relative;
  width: 110px; /* Same as image width */
  height: 100px; /* Same as image height */
}

.logoWithEffects {
  top: -10%;
  position: relative;
  z-index: 1;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));

}

.logoWrapper::before,
.logoWrapper::after {
  top: -10%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../public/logo512.png");
  background-size: cover;
  pointer-events: none;
}

.logoWrapper::before {
  z-index: 0;
  top: -10%;
  mix-blend-mode: overlay;
  filter: drop-shadow(0 0 10px white);
}

.logoWrapper::after {
  mix-blend-mode: screen;
  top: -10%;
  filter: blur(4px) saturate(550%);
  left: 0px;
  -webkit-filter: blur(4px) saturate(550%);
}

.logoWhite {
  mix-blend-mode: overlay;
  filter: saturate(150%);
  top: 10%;
}