  .sign-up-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  }
  
  .sign-up-form:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .sign-up-form h2 {
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
    color: #333;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 6px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .input-field:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .toggle-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    flex: 1;
    padding: 12px;
    margin: 0 5px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #555;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .toggle-button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
  }
  
  .toggle-button:not(.active):hover {
    background-color: #e0e0e0;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .link-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
    transition: color 0.3s;
  }
  
  .link-button:hover {
    color: #0056b3;
  }
  
  .error-message,
  .success-message {
    text-align: center;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    font-size: 14px;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  .form-title {
    text-align: center;
    margin: 40px 0 30px;
    font-size: 28px;
    font-weight: bold;
    color: #222;
    letter-spacing: 0.5px;
  }


  .account-prompt,
  .no-account-prompt {
    text-align: center;
    margin: 30px 0;
    font-size: 15px;
    color: #555;
  }
  
  .account-link,
  .no-account-link {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    transition: color 0.3s, text-decoration 0.3s;
  }
  
  .account-link:hover,
  .no-account-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  