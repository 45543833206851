/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Section Styles */
section {
  padding: 20px;
  box-sizing: border-box;
}

/* PolyCypher Description Section */
.polyCypher-desc {
  background-color: white;
  text-align: center;
}

.polyCypher-desc img {
  inline-size: 50px;
  block-size: auto;
}

.polyCypher-desc p {
  margin-block-start: 10px;
  color: #333;
  font-size: 1rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: center; /* This will center the grid items (cards) in the container */
  gap: 20px;
  padding: 20px;
}

/* Card Styles */
.card {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-inline-size: 300px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

.card h2 {
  font-size: 1.5rem;
  margin: 15px;
}

.card p {
  font-size: 0.9rem;
  padding: 0 15px 15px;
  text-align: justify;
}

/* Additional Section Styles */
.additional-section {
  background-color: #290033;
  color: white;
  text-align: center;
  padding: 60px 20px;
}

/* Footer Styles */
footer {
  background-color: #000;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 0.9rem;
}




.leadblock {
  background-image: url('../public/background_DNA.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll; /* Changed to 'scroll' to avoid potential blurring issues with 'fixed' in some browsers */
  color: white;
  text-align: start;
  padding: 120px; /* Adjust padding as needed */
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  block-size: 50vh;
  overflow: hidden; /* to clip the background image */
}


.leadblock-content {
  text-align: start;
  font-size: 4vh;
  z-index: 9;
  filter: none;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-attachment: scroll; /* this will move the background with the content */
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  position: relative; /* Establish a positioning context */
}

.leadblock-content::before {
  content: ''; /* Required for pseudo-elements */
  position: absolute; /* Position relative to .leadblock-content */
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  background-size: cover;
  background-position: center;
  z-index: 19;
  pointer-events: none; /* Prevent the pseudo-element from capturing mouse events */
}

.leadblock-subtitle {
  font-size: 1.25rem;
  margin-block-end: 30px;
  max-inline-size: 600px; 
  line-height: 1.6;
  z-index: 19;
  text-shadow:
    -0.25px -0.25px 0 rgb(94, 226, 255),  /* Top-left shadow */
    0.25px -0.25px 0 rgb(46, 105, 245),   /* Top-right shadow */
    -0.25px 0.25px 0 rgb(255, 126, 246),   /* Bottom-left shadow */
    0.25px 0.25px 0 rgb(255, 203, 236);    /* Bottom-right shadow */}

.leadblock-link {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border: 2px solid white;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  -webkit-transition: background-color 0.3s, color 0.3s;
  -moz-transition: background-color 0.3s, color 0.3s;
  -ms-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  z-index: 20;
}

.leadblock-link:hover {
  background-color: white;
  color: #290033; /* Change color on hover */
  z-index: 20;
}

.main-description {
  background-color: white;
  font-size: 3.5vh;
  max-inline-size: 60vw; /* Adjust this value as needed to control the width */
  margin-inline-start: auto;
  margin-inline-end: auto; /* These margins center the element */
  padding: 20px;
}


.leadblock-title s {
  position: relative; /* Establish a positioning context for the pseudo-element */
  text-decoration: none; /* Remove the default strikethrough */
  z-index: 15;
}

.leadblock-title s::after {
  content: '';
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 50%;
  border-block-end: 7px solid rgb(255, 255, 255);
  opacity: 0.95;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 15;
}

.leadblock-title s:hover::after {
  border-block-end-color: rgb(252, 217, 224);
  opacity: 1;
  transform: translateY(2px) scale(1.05);
  animation: shimmer 5s infinite;
  -webkit-animation: shimmer 5s infinite;
}

@keyframes shimmer {
  0% { box-shadow: 0 0 10px 0 rgba(252, 217, 224, 0.7); }
  50% { box-shadow: 0 0 20px 5px rgba(255, 119, 146, 0.5); }
  100% { box-shadow: 0 0 10px 0px rgba(252, 217, 224, 0.7); }
}


.leadblock-title {
  display: flex;
  justify-content: flex-start; /* Aligns items to the start of the container */
  font-size: 5.25rem;
  font-weight: 100;
}

.title-emphasis {
  color: #c8faff;
  font-weight: 700;
  position: relative;
  flex: 0 0 auto; /* Do not grow or shrink */
  padding-inline-start: 60%; /* Adjust the value as needed */
}


.carousel-words {
  position: absolute;
  text-align: start;
  inset-block-start: 50%; /* Center the item vertically */
  inset-inline-start: 50%; /* Position the left side of the text block */
  opacity: 0;
  transition: opacity 0.5s ease;
  white-space: nowrap;
  animation: carousel-animation 20s linear infinite, tron-animation 0.5s ease 5s forwards;
}




@keyframes carousel-animation {
  0%, 100% {
    transform: translate(-50%, 50%);
    opacity: 0;
    text-shadow: none;
    -webkit-transform: translate(-50%, 50%);
    -moz-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    -o-transform: translate(-50%, 50%);
}
  5%, 25% {
    transform: translate(-50%, -50%);
    opacity: 1;
    text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
  30%, 95% {
    transform: translate(-50%, -150%);
    opacity: 0;
    text-shadow: -25px -7px 0 rgb(246, 126, 255), 25px -7px 0 rgb(137, 255, 253), -7px 25px 0 rgb(236, 144, 255), 7px 25px 0 rgb(141, 141, 255);
  }
}

.carousel-words:nth-child(1) { animation-delay: 0s; }
.carousel-words:nth-child(2) { animation-delay: 5s; }
.carousel-words:nth-child(3) { animation-delay: 10s; }
.carousel-words:nth-child(4) { animation-delay: 15s; }







/* General Styles for Card Container and Cards */
/* General Container Styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.section-with-background {
  background-color: #171717;
  padding: 20px;
  font-family: 'Segoe', sans-serif; /* Professional font */
}

.section-with-background h2 {
  color: rgb(255, 255, 255); /* Dark text for titles */
  margin-bottom: 30px;
}

.card-container {
  max-width: 1200px; /* Container width */
  margin: 0 auto; /* Center align */
}

.st-card {
  background-color: rgb(0, 0, 49);
  /*cardbackground*/border: 2px solid rgb(27, 33, 61);
  /*Lightborderforthecard*/border-radius: 4px;
  /*Roundedcorners*/text-decoration: none;
  /*Removesunderlinefromlinks*/box-shadow: 0 2px 5px rgba(26, 16, 141, 0.2);
  /*Subtleshadowfordepth*/padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -ms-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -o-transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.st-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -o-transform: translateY(-1px);
}

.st-card h3 {
  color: #007bff; /* Company primary color for headings */
  margin-top: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.st-card p {
  color: rgb(236, 235, 235); /* Dark grey for readability */
  font-size: 15px; /* Standard font size for body text */
}










.section-with-background {
  position: relative;
  overflow: hidden;
  height: 100%; /* Set a specific height */
  z-index: 1; /* Higher z-index to ensure it's above its children */
}

.flow-background, .flow-field-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Lower z-index to keep them behind the parent */
}

.flow-field-container {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
}