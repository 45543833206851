.polycypher-report .report-header {
    background-color: #ffffff; /* White background */
    border-bottom: 3px solid #004080; /* Dark blue border */
    padding: 40px 0;
    margin-bottom: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .polycypher-report .report-header {
    color: #004080; /* Dark blue font */
    font-size: 50px; /* Larger font size */
    font-weight: 1000; /* Bold */
    margin: 0;
    padding: 5;
    text-align: center;
    font-family: 'Arial', 'Helvetica', sans-serif;
    letter-spacing: 1px;
  }
  
  @media (max-width: 768px) {
    .polycypher-report .report-header {
      padding: 30px 0;
      margin-bottom: 40px;
    }
  
    .polycypher-report .report-header h1 {
      font-size: 28px; /* Adjusted size for mobile */
    }
  }
  .custom-multi-select {
    position: relative;
  }
  
  .multi-select-header {
    background-color: white;
    border: 1px solid #bdc3c7;
    border-radius: 6px;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .multi-select-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #bdc3c7;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .multi-select-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
  }
  
  .multi-select-list li:hover {
    background-color: #f1f8ff;
  }
  
  .multi-select-list li.selected {
    background-color: #e1f0ff;
  }
  
  .multi-select-list li input[type="checkbox"] {
    margin-right: 10px;
  }
  .height-input-container {
    display: flex;
    gap: 15px;
  }
  
  .height-input-field {
    display: flex;
    align-items: center;
  }
  
  .height-input-field input {
    width: 60px;
    padding: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 5px;
  }
  
  .height-input-field label {
    font-size: 14px;
    color: #7f8c8d;
  }




  .patient-info-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
  }
  
  .patient-info-title {
    color: #2c3e50;
    font-size: 1.8em;
    margin-bottom: 20px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  .patient-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .patient-info-item {
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .patient-info-item:hover {
    background-color: #e8f4fd;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .info-label {
    font-weight: bold;
    color: #34495e;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  
  .info-value {
    color: #2c3e50;
    font-size: 1.1em;
  }
  
  .survey-section {
    margin-top: 20px;
  }
  
  .survey-header {
    background-color: #3498db;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .survey-header:hover {
    background-color: #2980b9;
  }
  
  .survey-header.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .survey-form {
    background-color: #f8f9fa;
    border: 1px solid #3498db;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #2c3e50;
    font-weight: bold;
  }
  
  .survey-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #bdc3c7;
    border-radius: 6px;
    font-size: 1em;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .survey-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
  
  .custom-dropdown {
    position: relative;
  }
  
  .dropdown-header {
    background-color: white;
    border: 1px solid #bdc3c7;
    border-radius: 6px;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #bdc3c7;
    border-top: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-list li:hover {
    background-color: #f1f8ff;
  }
  
  .submit-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.3s;
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #27ae60;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .patient-info-grid {
      grid-template-columns: 1fr;
    }
  }







.overview-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }





  .details-table {
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .table-title {
    background: linear-gradient(to right, #3498db, #2c3e50);
    color: #ffffff;
    padding: 20px;
    margin: 0;
    font-size: 24px;
  }
  
  .table-container {
    overflow-x: auto;
    padding: 20px;
  }
  
  .details-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .details-table th,
  .details-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .details-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    color: #2c3e50;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  
  .details-table tr:nth-child(even) {
    background-color: #f8f9fa;
  }
  
  .details-table .disease {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .details-table .percentile-risk {
    background-color: #e3f2fd;
    color: #1565c0;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .details-table .relative-risk,
  .details-table .absolute-risk,
  .details-table .polygenic-risk {
    color: #333;
  }






  .visualizations {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .chart-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }
  
  .chart-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .chart-card h3 {
    color: #2c3e50;
    font-size: 1.5em;
    margin-bottom: 15px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  .chart-description {
    margin-top: 15px;
    font-size: 0.9em;
    color: #7f8c8d;
    line-height: 1.6;
  }
  
  .custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .custom-tooltip .disease {
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 5px;
  }
  
  .custom-tooltip p {
    margin: 2px 0;
    color: #34495e;
  }
  
  .risk-correlation-tooltip,
  .risk-comparison-tooltip {
    min-width: 200px;
  }
  
  @media (max-width: 768px) {
    .visualizations {
      flex-direction: column;
    }
  
    .chart-card {
      width: 100%;
    }
  }




  .histograms {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    padding: 0 10px; /* Tiny gap from the edges */
}

.risk-histogram {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 10px;
    flex: 300px; /* Maintain card width */
    max-width: 350px; /* Prevent growth beyond 300px */
}



  .risk-histogram h3 {
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .risk-histogram p {
    color: #34495e;
    margin-bottom: 15px;
  }
  
  .risk-legend {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .risk-legend span {
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .lower-risk {
    color: #2980b9; /* Blue for lower risk */
  }
  
  .average-risk {
    color: #8e44ad; /* Purple for average risk */
  }
  
  .higher-risk {
    color: #c0392b; /* Red for higher risk */
  }
  
  .bar {
    background: linear-gradient(to top, #c0392b, transparent 90%);
  }
  





  .risk-interpretation {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 30px;
  }
  
  .risk-interpretation h2 {
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .risk-interpretation p,
  .risk-interpretation ul {
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .risk-interpretation ul {
    padding-left: 20px;
  }
  
  .risk-interpretation li {
    margin-bottom: 10px;
  }
  
  .custom-tooltip {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .custom-tooltip .label {
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .custom-tooltip .value {
    color: #34495e;
  }
  
  @media (max-width: 768px) {
    .patient-info-grid {
      grid-template-columns: 1fr;
    }
  
    .tabs {
      flex-wrap: wrap;
    }
  
    .tab {
      margin-bottom: 10px;
    }
  
    .overview-charts,
    .histograms {
      grid-template-columns: 1fr;
    }
  }

  .report-tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .report-tab {
    background-color: #f0f0f0; /* Light gray background for non-selected tabs */
    color: #333; /* Dark text for contrast */
    padding: 10px;
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .report-tab:hover {
    background-color: #e0e0e0; /* Slightly darker gray on hover */
  }
  
  .report-tab.active {
    background-color: #004080; /* Dark blue for selected tab */
    color: #fff; /* White text for contrast */
  }

  .chart-container {
    display: flex;
    flex-direction: row;
  }

  .disease-menu {
    width: 200px;
    overflow-y: auto;
    max-height: 500px;
    padding-right: 20px;
    background-color: #ffffff; /* White background */
    color: #000000; /* Black text color */
  }
  
  .disease-button {
    display: block;
    width: calc(100% - 10px); /* Adjust width for left spacing */
    text-align: left;
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f8f8f8; /* Light gray background */
    color: #333333; /* Dark gray text color */
    border: 1px solid transparent; /* Thinner outline */
    cursor: pointer;
    margin-left: 10px; /* Slight left spacing */
  }
  
  .disease-button:focus {
    outline: 1px solid #007bff; /* Thin blue outline when selected */
  }
  
  .disease-button.active {
    background-color: #e0e0e0; /* Slightly darker gray background */
    color: #000000; /* Black text color */
    font-weight: bold;
  }
  