.example-report {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    margin: 20px auto;
    max-width: 1200px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .App input[type="file"] {
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-weight: bold;
    cursor: pointer;
  }
  
  .header {
    background-color: #2c3e50;
    text-align: center;
    padding: 20px;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  .report-content {
    padding: 30px;
  }
  
  .report-content section {
    margin-bottom: 40px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
  }
  
  .report-content h2 {
    color: #2c3e50;
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .report-content p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .report-content a {
    display: block;
    margin: 20px 0;
    color: #2980b9;
    text-decoration: none;
    font-weight: bold;
  }
  
  .report-content a:hover {
    text-decoration: underline;
  }
  
  .report-content svg {
    max-width: 100%;
    height: auto;
  }
  
  .footer {
    background-color: #2c3e50;
    color: white;
    text-align: center;
    padding: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }  