/* Main about section */
.about {
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;  /* Add Roboto font to the entire .about section */
}

/* Three.js container */
.three-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Content container */
.content {
  padding: 50px;
  z-index: 1;
}

/* Black blur overlay on the background */
.background {
  position: fixed;
  top: -5%;
  left: -5%;
  right: -5%;
  bottom: -5%;
  width: 110%;
  height: 110%;
  z-index: 0;
  filter: blur(20px);
  filter: blur(10px) brightness(0.9);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.146), rgba(0, 0, 0, 0.973));
  -webkit-filter: blur(10px) brightness(0.9);
  filter: url(#complex-blur);
}


/* Founder card container */
.founder-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for larger screens */
  gap: 4%; /* Adjust the gap between cards */
}

@media (max-width: 768px) { /* Adjust the max-width as needed */
  .founder-container {
    grid-template-columns: 1fr; /* One column for smaller screens */
    grid-template-rows: auto auto auto; /* Three rows */
    position: relative; /* Relative positioning for the container */
  }}


/* Individual founder card */
.founder-card {
  position: relative;
  backdrop-filter: blur(15px) saturate(450%);  /* Added saturate for vibrancy */
  width: 100%;
  padding: 2%;
  border: 1px solid white;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  transition: transform 0.5s, opacity 1s ease-out;
  opacity: 0;
  transform: translateY(100px);
  perspective: 1000px;
  transition: all 1s ease-in-out;
  transform-style: preserve-3d;
  overflow: hidden;
  -webkit-transition: transform 0.5s, opacity 1s ease-out;
  -moz-transition: transform 0.5s, opacity 1s ease-out;
  -ms-transition: transform 0.5s, opacity 1s ease-out;
  -o-transition: transform 0.5s, opacity 1s ease-out;
}

.founder-card canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Visible founder card */
.founder-card.visible {
  opacity: 1;
  transform: translateY(0);
  transform: rotateY(360deg);
}

/* Founder card image */
.founder-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  transform: rotateY(0deg);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Founder card content */
.founder-card .card-content {
  position: absolute;
  backface-visibility: hidden;
  transform: translateZ(50px);
}

/* H1 title inside content */
.content h1 {
  z-index: 23 !important;
  position: relative !important;
  filter: blur(0) !important;
  background-color: rgba(0, 0, 0, 0.158) !important;
  display: inline-block !important;
  padding: 15px 25px !important;
  transform: translateZ(0) !important;
  text-shadow: 0 0 0 #000 !important;
  will-change: transform, opacity !important;
  border: 1px solid transparent !important;
  backface-visibility: visible !important;
  outline: 1px solid transparent !important;
  -webkit-backface-visibility: visible !important;
}

/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Non-blur title */
.nonBlurTitle {
  /* Typography */
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  line-height: 1.2;
  letter-spacing: 1px;
  
  /* Layout */
  margin: 2rem auto;
  padding: 0;  /* No padding for a minimalist look */
  
  /* Styling */
  z-index: 10;  /* Ensure it stays on top if needed */
  position: relative;
  
  /* Animation for a smooth transition */
  transition: color 0.3s ease-in-out;
}

/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Container for company info */
.company-info {
  text-align: center;
  justify-content: center;

  /* Layout */
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;  /* Increased padding */

  /* Styling */
  background-color: rgba(0, 0, 0, 0.097);  /* Darker background for more shade */
  backdrop-filter: blur(15px) saturate(300%) hue-rotate(30deg);  /* Added saturate for vibrancy */
  border-radius: 15px;  /* Slightly rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.088);  /* Subtle shadow for depth */
}

/* Paragraph styling */
.company-info p {
  /* Typography */
  justify-content: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;  /* Increased font size */
  font-weight: 200;  /* Bold */
  line-height: 1.7;  /* Better line height for readability */
  color: #FFFFFF;  /* Pure white */

  /* Layout */
  margin: 0;
  padding: 0;
}

.header {
  z-index: 11; /* Or any value higher than the other elements */
  position: relative; /* z-index only works on positioned elements (position:absolute, position:relative, or position:fixed) */
}

.header {
  pointer-events: auto;
}