.user-details-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100vw;
  background-color: #f9f9f9;
}

#d3-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.user-info-panel {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  padding: 30px;
  text-align: center;
  margin: 20px;
}

.user-greeting {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.user-info {
  margin: 5px 0;
  color: #666;
}

.signout-button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.signout-button:hover {
  background-color: #45a049;
}

.dashboard-button {
  display: block;
  width: 100%;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.error-message {
  color: red;
  font-weight: bold;
}

.loading-message {
  color: #666;
  font-style: italic;
}

.debug-info {
  margin-top: 10px;
  font-size: 0.8em;
  color: #666;
  white-space: pre-wrap;
}
