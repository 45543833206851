/* Footer.css */





.footer {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px; /* Base font size */
    position: relative !important;
    /*1.Forcepositionrelative*/z-index: 9999 !important;
    /*5.Use3Dtranslationto'pull'elementstowardstheviewer*/filter: brightness(1.1) !important;
    /*6.Slightlyincreasebrightness*/opacity: 1 !important;
    /*7.Ensurefullopacity*/visibility: visible !important;
    /*8.Ensurevisibility*/pointer-events: auto !important;
    /*9.Ensureit'sinteractable*/clip: auto !important;
    -webkit-transform: translateZ(9999px) !important;
    -moz-transform: translateZ(9999px) !important;
    -ms-transform: translateZ(9999px) !important;
    -o-transform: translateZ(9999px) !important;
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
    text-align: left;
    z-index: 55;
  }
  
  .logo .footer-logo {
    width: 70%; /* Adjust as needed */
    height: auto;
    margin-bottom: 20px;
  }
  
  .text .tagline {
    font-size: 1.25em; /* 20px if the base font size is 16px */
    margin-bottom: 5px;
  }
  
  .text .copyright {
    font-size: 0.875em; /* 14px if the base font size is 16px */
  }
  
  .contact .contact-title {
    font-size: 1em; /* 16px if the base font size is 16px */
    margin-bottom: 10px;
  }
  
  .contact-info {
    display: block;
    color: white;
    text-decoration: none;
    margin-bottom: 8px;
    font-size: 0.875em; /* Adjust the font size as needed */
  }
  
  .social-media {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .social-link {
    display: inline-block;
    margin-right: 10px;
  }
  
  .social-link img {
    width: 24px; /* Adjust as needed */
    height: auto;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
    }
  
    .footer-section {
      align-items: center;
      text-align: center;
      z-index: 55;
    }
  
    .social-media {
      justify-content: center;
    }
  }  