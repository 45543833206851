/* Placeholder.css */

.accessibility-info, .discount-form {
  margin-bottom: 20px;
  padding: 20px; /* Added padding for more space */
}

.input-fields {
  max-width: 300px; /* Maximum width for input fields */
  margin: 20px auto; /* Center align the input fields and add vertical margin */
  padding: 20px; /* Added padding for the input fields area */
  background-color: #fff; /* Optional: Add a background color */
  border-radius: 8px; /* Optional: Round corners for the input fields container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
}

.input-fields input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  width: calc(100% - 22px); /* Adjust width for padding and border */
}

button {
  display: block; /* Make the button a block element */
  width: 50%; /* Set a specific width for the button */
  margin: 10px auto; /* Center the button horizontally and add margin */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.discount-result {
  color: rgb(255, 255, 255) !important;
  text-align: center;
  font-weight: bold;
  font-size: large;
}
