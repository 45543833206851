/* Reset any margins and paddings to ensure consistency */
* {
  margin: 0;
  padding: 0;
}

.screen-reader-text {
  position: absolute;
  inset-inline-start: -10000px;
  inset-block-start: auto;
  inline-size: 1px;
  block-size: 1px;
  overflow: hidden;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background: #000; /* Pure black background */
  position: relative;
  z-index: 9999; /* High z-index to ensure header is above all other content */
}

.logo-container {
  flex: 0 1 auto;
  order: -1;
  padding-inline-end: 2rem;
  padding-inline-start: 2rem;
}

.logo {
  block-size: auto;
  inline-size: 6rem; /* Adjust as needed to make the logo a bit smaller */
  max-inline-size: 100%;
}

.navigation-menu {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.menu-list {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin-inline-end: 1rem; /* Adjust spacing between menu items */
}

.menu-link {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  transition: color 0.3s;
  pointer-events: auto; /* Ensure links are always clickable */
}

.menu-link:hover,
.menu-link.active {
  color: #89b7e9;
}

.login-container {
  margin-inline-start: auto;
  padding-inline-start: 2rem; /* Ensures some space from the last menu item */
  padding-inline-end: 2rem; /* Ensures some space from the last menu item */
}

/* Style for the Login button */
.login-button {
  background-color: #28598d;
  /*Bootstrapprimarybuttoncolor*/border: 1px solid transparent;
  border-radius: 0.25rem;
  color: white;
  padding: 0.1rem 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-button:hover,
.login-button:focus {
  background-color: #420776; /* Darken color on hover/focus for visual feedback */
  text-decoration: none; /* Ensure underline does not reappear on hover */
  color: white;
}